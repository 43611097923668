import { graphql } from 'gatsby';
import * as React from 'react';
import AlertList from '../AlertList';
import { Column, Container } from '../Grid';
import PageHelmet from '../PageHelmet';
import ProductFamilyNav from '../ProductFamilyNav';
import { SectionRow } from '../Section';
import SectionTitle from '../SectionTitle';
import { AllProductFamilyProps, AllProductProps, ProductProps } from '../types';
import ProductContent from './ProductContent';

export const productFragment = graphql`
  fragment ProductFragment on ContentfulProduct {
    id
    title
    pageHeaderTitle
    h1Title
    slug
    metaKeywords {
      metaKeywords
    }
    metaDescription {
      metaDescription
    }
    metaRobots
    copy {
      childMarkdownRemark {
        html
      }
    }
    specifications {
      childMarkdownRemark {
        html
      }
    }
    productFamily {
      title
      slug
      parentSection {
        ...SectionFragment
      }
    }
    productImage {
      resolutions(width: 300, quality: 70) {
        src
      }
    }
    brochure {
      file {
        url
      }
    }
  }
`;

// tslint:disable:object-literal-sort-keys
const getStructuredData = ({
  contentfulProduct: { title, productImage, metaDescription }
}: ProductProps) => ({
  '@context': 'http://schema.org',
  '@type': 'Product',
  name: title,
  image: [productImage.resolutions.src],
  description: metaDescription ? metaDescription.metaDescription : '',
  brand: { '@type': 'Thing', name: 'RBV Energy' }
});
// tslint:enable:object-literal-sort-keys

const getBackgroundImage = (productFamily: any) => {
  const [pageTitle] = productFamily.parentSection?.pageTitle || [];
  return (pageTitle as any)?.backgroundImage;
};

const Product = ({
  contentfulProduct,
  allContentfulProductFamily,
  allContentfulProduct
}: ProductProps & AllProductFamilyProps & AllProductProps) => {
  const {
    id,
    metaDescription,
    metaKeywords,
    metaRobots,
    title,
    h1Title,
    copy,
    productFamily,
    specifications
  } = contentfulProduct;

  const structuredData = getStructuredData({ contentfulProduct });
  const backgroundImage = getBackgroundImage(productFamily[0]);

  return (
    <>
      <PageHelmet
        metaDescription={metaDescription}
        metaKeywords={metaKeywords}
        metaRobots={metaRobots}
        id={id}
        script={[
          {
            innerHTML: JSON.stringify(structuredData),
            type: 'application/ld+json'
          }
        ]}
      />
      <SectionTitle
        headline={h1Title || title}
        backgroundImage={backgroundImage}
      />
      <Container>
        <AlertList />
        <SectionRow>
          <Column lg={3}>
            <ProductFamilyNav
              contentfulProductFamily={productFamily[0]}
              allContentfulProductFamily={allContentfulProductFamily}
              contentfulProduct={contentfulProduct}
              allContentfulProduct={allContentfulProduct}
            />
          </Column>
          <Column lg={9}>
            <ProductContent contentfulProduct={contentfulProduct} />
          </Column>
        </SectionRow>
      </Container>
    </>
  );
};

export default Product;
