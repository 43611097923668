import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from '../components/Layout';
import Product from '../components/Product';
import {
  AllProductFamilyProps,
  AllProductProps,
  AllSectionProps,
  ProductProps
} from '../components/types';
import { normalizePath } from '../utils';

export const productQuery = graphql`
  query ProductQuery($id: String!, $productFamilyId: String) {
    ...AllSectionFragment
    ...AllProductFamilyFragment
    contentfulProduct(id: { eq: $id }) {
      ...ProductFragment
    }
    allContentfulProduct(
      filter: { productFamily: { elemMatch: { id: { eq: $productFamilyId } } } }
      sort: { fields: [title], order: ASC }
    ) {
      edges {
        node {
          ...ProductListItemFragment
        }
      }
    }
  }
`;

const getBreadcrumbPath = (props: ProductProps & AllSectionProps) => {
  const home = props.allContentfulSection.edges[0].node;
  const productFamily = props.contentfulProduct.productFamily[0];
  const section = productFamily.parentSection;

  const active = {
    slug: props.contentfulProduct.slug,
    title: props.contentfulProduct.title
  };

  return [home, section, productFamily, active].map(({ slug, title }) => ({
    slug,
    title
  }));
};

const ProductTemplate = ({
  data,
  location
}: {
  data: AllProductFamilyProps &
    AllSectionProps &
    ProductProps &
    AllProductProps;
  pageContext: any;
  location: { pathname: string };
}) => {
  const breadcrumbPath = getBreadcrumbPath(data);
  const currentSectionPath = normalizePath(breadcrumbPath[1].slug);
  const currentLocation = normalizePath(location.pathname);

  return (
    <Layout
      title={
        data.contentfulProduct.pageHeaderTitle || data.contentfulProduct.title
      }
      breadcrumbPath={breadcrumbPath}
      currentSectionPath={currentSectionPath}
      currentLocation={currentLocation}
      menuData={data}
    >
      <Product
        contentfulProduct={data.contentfulProduct}
        allContentfulProduct={data.allContentfulProduct}
        allContentfulProductFamily={data.allContentfulProductFamily}
      />
    </Layout>
  );
};

export default ProductTemplate;
