import breakpoint from 'styled-components-breakpoint';
import styled from '../../styled-components';
import MarkdownComponent from '../MarkdownComponent';

const ProductSpecifications = styled(MarkdownComponent)`
  clear: both;
  display: flex;
  flex-direction: column;
  h1,
  h2,
  h3 {
    font-family: ${props => props.theme.bodyFont.family};
    font-size: 1em;
    flex-shrink: 0;
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.secondaryColor};
    padding-right: ${props => props.theme.baseMargin}rem;
    padding-bottom: ${props => props.theme.baseMargin / 4}rem;
    margin-bottom: 0.5rem;
    color: ${props => props.theme.primaryColor};
    font-weight: ${props => props.theme.bodyFont.weight};
    line-height: ${props => props.theme.baseLineHeight}rem;
  }
  p {
    flex-shrink: 1;
    width: 100%;
    line-height: ${props => props.theme.baseLineHeight}rem;
    font-size: 1em;
    margin-bottom: ${props => props.theme.baseMargin}rem;
    padding-bottom: ${props => props.theme.baseMargin / 4}rem;
    &::after {
      flex-basis: 100%;
    }
  }

  > * {
    &:last-child {
      margin-bottom: ${props => props.theme.baseMargin}rem;
    }
  }

  ${props => breakpoint('md')`
    flex-direction: row;
    flex-wrap: wrap;
    h1,
    h2,
    h3 {
      width: 30%;
      margin-bottom: ${props.theme.baseMargin}rem;
    }
    p {
      width: 70%;
      border-bottom: 1px solid ${props.theme.secondaryColor};
    }
`};
`;

export default ProductSpecifications;
