import * as React from 'react';
// tslint:disable-next-line:no-submodule-imports
import { FilePdf } from 'styled-icons/fa-solid/FilePdf';
import CallToAction, { CallToActionContainer } from '../CallToAction';
import MarkdownComponent from '../MarkdownComponent';
import { ProductProps } from '../types';
import BrochureButton from './BrochureButton';
import ProductImage from './ProductImage';
import ProductSpecifications from './ProductSpecifications';

const ProductContent = ({ contentfulProduct }: ProductProps) => {
  const { specifications, copy } = contentfulProduct;
  return (
    <div>
      {contentfulProduct.productImage ? (
        <ProductImage src={contentfulProduct.productImage.resolutions.src} />
      ) : null}
      <MarkdownComponent key="copy" {...copy} />
      <CallToActionContainer>
        <CallToAction
          key="call-to-action-button"
          navigateToSlug="/contact-us/"
          buttonText="Contact us today"
        />
        {contentfulProduct.brochure ? (
          <BrochureButton
            key="brochure-button"
            href={contentfulProduct.brochure.file.url}
          >
            <FilePdf size={16} />
            Technical brochure
          </BrochureButton>
        ) : null}
      </CallToActionContainer>
      {specifications ? (
        <ProductSpecifications key="specifications" {...specifications} />
      ) : null}
    </div>
  );
};

export default ProductContent;
