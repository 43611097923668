import breakpoint from 'styled-components-breakpoint';
import styled from '../../styled-components';

const ProductImage = styled.img`
  margin-bottom: ${props => props.theme.baseMargin}rem;
  margin-left: auto;
  margin-right: auto;
  ${props => breakpoint('lg')`
    float: left;
    margin-left: 0;
    margin-right: ${props.theme.baseMargin}rem;
  `};
  display: block;
  max-width: 100%;
  max-height: 100%;
  margin-top: 0;
  border-radius: 0.3rem;
  background-color: $notsodark;
`;

export default ProductImage;
