import breakpoint from 'styled-components-breakpoint';
import styled from '../../styled-components';

const BrochureButton = styled.a`
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 0.375rem 1.5rem;
  border-radius: 0.25rem;
  width: auto;
  background-color: ${props => props.theme.semiTransparentBackgroundColor};
  color: ${props => props.theme.color};
  margin-bottom: ${props => props.theme.baseMargin}rem;

  svg {
    margin-right: 0.5rem;
  }

  ${props => breakpoint('md')`
  padding-left: 0;
  padding-right: 0;
`};
`;

export default BrochureButton;
